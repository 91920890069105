<template>
  <div class="index">
    <HomeBanner />
    <div class="learning-history">
      <template v-if="false"><LearnHistory /></template><template v-else> <LearnRoadmap /></template>
    </div>
    <HomeLearn />
    <HomePractices />
  </div>
</template>

<script setup lang="ts">
import HomeBanner from '@/components/home/HomeBanner.vue';
import LearnHistory from '@/components/home/LearnHistory.vue';
import HomeLearn from '@/components/home/learn/HomeLearn.vue';
import HomePractices from '@/components/home/practice/HomePractices.vue';
import LearnRoadmap from '@/components/home/roadmap/LearnRoadmap.vue';
import { useAuthStore } from '@/stores';

const authStore = useAuthStore();
</script>

<style scoped lang="scss">
.index {
  width: 1200px;
  margin: 0 auto;
  .learning-history {
    margin-top: 60px;
  }
}
</style>
