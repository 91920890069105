<template>
  <div
    :style="{
      left: chooseStatus ? '-10px' : '0',
    }"
    :class="`${chooseStatus ? 'choosed' : ''}`"
    class="HomePlanPickerItem-wrapper"
  >
    <div class="bar">
      <div style="margin-top: 5px">
        <span>学习路径</span>
      </div>
      <div class="trapezoid">
        <img :src="arrowDown" alt="arrowDown" />
      </div>
    </div>
    <div
      :style="{
        opacity: chooseStatus ? '0' : '1',
      }"
      class="bar-default"
    />
    <div
      :style="{
        opacity: chooseStatus ? '1' : '0',
      }"
      class="bar-choosed"
    />

    <div class="content">
      <span>{{ menuItem.title }}</span>
    </div>
    <div
      :style="{
        opacity: chooseStatus ? '0' : '1',
      }"
      class="content-default"
    />
    <div
      :style="{
        opacity: chooseStatus ? '1' : '0',
      }"
      class="content-choosed"
    />
  </div>
  <div
    :style="{
      left: chooseStatus ? '-10px' : '0',
      opacity: chooseStatus ? '0' : '1',
    }"
    class="HomePlanPickerItem-wrapper-default"
  />
  <div
    :style="{
      left: chooseStatus ? '-10px' : '0',
      opacity: chooseStatus ? '1' : '0',
    }"
    class="HomePlanPickerItem-wrapper-choosed"
  />
</template>

<script setup lang="ts">
import { toRefs, computed } from 'vue';
import arrowDown from '@/assets/svgs/arrow-down.svg';

const props = defineProps<{
  menuItem: {
    title: string;
    status: boolean;
  };
}>();

const { menuItem } = toRefs(props);

const chooseStatus = computed<boolean>(() => {
  return props.menuItem.status;
});
</script>

<style scoped lang="scss">
.HomePlanPickerItem-wrapper {
  position: relative;
  width: 240px;
  height: 80px;
  margin: 1px;
  display: flex;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s;
  .bar {
    position: relative;
    width: 28px;
    display: flex;
    align-items: center;
    writing-mode: vertical-rl;
    font-family: dingding;
    font-size: 10px;
    transition: all 0.5s;
    z-index: 1;
    .trapezoid {
      position: relative;
      width: 28px;
      height: 27px;
      background-color: transparent;
      overflow: hidden;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 0);
      }
    }
    .trapezoid::before {
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      border-left: 100px solid #383a46;
      border-top: 20px solid transparent;
      width: 100%;
      height: 0;
      transition: all 0.5s;
    }
  }
  .bar-default {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 80px;
    background: linear-gradient(to right, #898b91, #ffffff);
    transition: all 0.5s;
  }
  .bar-choosed {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 80px;
    background: linear-gradient(to right, #6ed4ff, #fff);
    transition: all 0.5s;
  }
  .content {
    position: relative;
    width: 212px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: dingding;
    font-size: 18px;
    transition: all 0.5s;
    z-index: 1;
  }
  .content-default {
    position: absolute;
    top: 0;
    left: 28px;
    width: 212px;
    height: 80px;
    background: linear-gradient(to right, #cdcfd0, #ffffff);
    transition: all 0.5s;
  }
  .content-choosed {
    position: absolute;
    top: 0;
    left: 28px;
    width: 212px;
    height: 80px;
    background: linear-gradient(to right, #d8f3ff, #ffffff);
    transition: all 0.5s;
  }
}
.HomePlanPickerItem-wrapper-default {
  position: absolute;
  margin-top: -82px;
  width: 242px;
  height: 82px;
  background: linear-gradient(to right, #696b73, #eed3da);
  transition: all 0.5s;
}
.HomePlanPickerItem-wrapper-choosed {
  position: absolute;
  margin-top: -82px;
  width: 242px;
  height: 82px;
  background: linear-gradient(to right, #23beff, #d7f3ff);
  transition: all 0.5s;
}

.choosed {
  .bar {
    .trapezoid::before {
      border-left-color: #23beff;
    }
  }
}
</style>
