<template>
  <div class="HomePractices-wrapper">
    <el-row type="flex" justify="center">
      <Title label="AI PRACTICE" title="AI实践" />
    </el-row>
    <el-row style="margin-top: 60px" type="flex" justify="space-between">
      <div v-for="practice in practiceList" :key="practice.practice_id">
        <a :href="practice.practice_link" target="_blank">
          <HomePracticeItem :practice-info="practice" />
        </a>
      </div>
    </el-row>
    <More :push-name="'practice'" />
    <div class="background">
      <img :src="homePracticeBg" alt="homePracticeBg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { getPracticeList, type PracticesPracticeInfo, type PracticeTag, getPracticeTags } from '@/apis/practice';
import homePracticeBg from '@/assets/svgs/home-practice-bg.svg';
import Title from '../utils/Title.vue';
import HomePracticeItem from './HomePracticeItem.vue';
import { mapToPracticeInfo } from '@/utils/mapper';
import More from '../utils/More.vue';

const practiceList = ref<PracticesPracticeInfo[]>([]);
const practiceTags = ref<PracticeTag[]>([]);

onMounted(async () => {
  const {
    data: { categoryMap, difficultyMap, practices },
  } = await getPracticeList({
    page: 1,
    size: 3,
  });
  const {
    data: { tags },
  } = await getPracticeTags();
  practiceTags.value = tags;
  practiceList.value = mapToPracticeInfo(practiceTags, practices, categoryMap, difficultyMap);
});
</script>

<style scoped lang="scss">
.HomePractices-wrapper {
  margin: 60px 0;
  position: relative;
  width: 100%;
  a {
    text-decoration: none;
  }
  .placeholder {
    width: 358px;
    height: 180px;
    margin-bottom: 30px;
  }
  .background {
    position: absolute;
    width: 242px;
    height: 242px;
    top: -10px;
    left: -110px;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
