<template>
  <div class="HomeWelcome-wrapper">
    <div class="part1">
      <div class="title">
        <span>AI学习，开始于此</span>
      </div>
      <div class="note">
        <span
          >在一个数据驱动的世界里，人工智能的未来应由每一个愿意学习和探索的人共同塑造和掌握。希望这里是你实现AI梦想的起点。</span
        >
      </div>
      <div class="buttons">
        <div class="buttons-item" @click="router.push('learn')">
          <span>开始学习</span>
          <img class="buttoms-item-bg" :src="LearnButton" alt="LearnButton" />
        </div>
        <div class="buttons-item" @click="router.push('practice')">
          <span>开始实践</span>
          <img class="buttoms-item-bg" :src="PracticeButton" alt="PracticeButton" />
        </div>
      </div>
    </div>
    <div class="part2">
      <img :src="HomeImg" alt="HomeImg" />
    </div>
  </div>
  <div class="event-wrapper">
    <div class="item" v-for="(event, index) in eventList" :key="event.event_name">
      <a :href="event.event_link" target="_blank">
        <HomeEventItem :event="event" />
      </a>
      <div v-if="index !== eventList.length - 1" class="stick" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type HomeEventInfo } from '@/apis/home';
import PracticeButton from '@/assets/imgs/buttom-black.png';
import LearnButton from '@/assets/imgs/buttom-normal.png';
import logo1 from '@/assets/svgs/event-icon1.svg';
import logo2 from '@/assets/svgs/event-icon2.svg';
import logo3 from '@/assets/svgs/event-icon3.svg';
import logo4 from '@/assets/svgs/event-icon4.svg';
import HomeImg from '@/assets/svgs/home-img.svg';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import HomeEventItem from './HomeEventItem.vue';

const router = useRouter();

const eventList = ref<HomeEventInfo[]>([
  {
    event_name: '零基础入门机器学习竞赛',
    event_info: '2024-07-01',
    event_link: 'https://www.datawhale.cn/activity/6',
    event_logo: logo1,
  },
  {
    event_name: '零基础入门大模型技术竞赛',
    event_info: '2024-07-01',
    event_link: 'https://www.datawhale.cn/activity/6',
    event_logo: logo2,
  },
  {
    event_name: '零基础开始大模型应用开发',
    event_info: '2024-07-01',
    event_link: 'https://www.datawhale.cn/activity/6',
    event_logo: logo3,
  },
  {
    event_name: 'AI夏令营',
    event_info: '2024-07-01',
    event_link: 'https://www.datawhale.cn/activity/6',
    event_logo: logo4,
  },
]);
</script>

<style scoped lang="scss">
.HomeWelcome-wrapper {
  margin-top: 78px;
  width: 100%;
  height: 380px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .part1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 656px;
    height: 248px;
    .title {
      font-size: 64px;
      font-family: dotted;
      color: #111111;
      letter-spacing: 5px;
      text-shadow: 2.3px 2.3px 0px #727375;
      -webkit-text-stroke: 0.15px #ffffff;
    }
    .note {
      line-height: 32px;
      font-size: 16px;
    }
    .buttons {
      display: flex;
      user-select: none;
      height: 40px;
      &-item {
        width: 120px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: dingding;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        transition: all 0.2s;
        &:nth-child(1) {
          margin-right: 30px;
        }
        .buttoms-item-bg {
          position: absolute;
          width: 120px;
          height: 40px;
          transition: all 0.2s;
          z-index: -1;
        }
      }
      &-item:hover {
        margin-top: -4px;
      }
    }
  }
  .part2 {
    width: 410px;
    height: 380px;
    margin-right: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.event-wrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .item {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
    .stick {
      width: 2px;
      height: 100px;
      background: #ddd;
    }
  }
}
</style>
