<template>
  <div class="HomePlanPicker-wrapper">
    <div class="list">
      <div v-for="(item, index) in plans" :key="index" @click="choosePlan(index)" @mouseenter="choosePlan(index)">
        <HomePlanPickerItem :menu-item="item" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import HomePlanPickerItem from './HomePlanPickerItem.vue';
import dataAnalysis from '@/assets/imgs/data-analysis.png';
import computerVision from '@/assets/imgs/computer-vision.png';
import naturalLanguageProcessing from '@/assets/imgs/natural-language-processing.png';
import recommendedSystem from '@/assets/imgs/recommended-system.png';

const emits = defineEmits<{
  (event: 'choosePlan', pic: string): void;
}>();

// 学习计划列表
const plans = ref<
  {
    title: string;
    status: boolean;
    road_pic: string;
  }[]
>([
  {
    title: '数据分析学习路径',
    status: true,
    road_pic: dataAnalysis,
  },
  {
    title: '计算机视觉学习路径',
    status: false,
    road_pic: computerVision,
  },
  {
    title: '自然语言处理学习路径',
    status: false,
    road_pic: naturalLanguageProcessing,
  },
  {
    title: '推荐系统学习路径',
    status: false,
    road_pic: recommendedSystem,
  },
]);

const choosePlan = (index: number) => {
  plans.value.forEach((item, i) => {
    if (i === index) {
      item.status = true;
      emits('choosePlan', item.road_pic);
    } else {
      item.status = false;
    }
  });
};

onMounted(() => {
  emits('choosePlan', plans.value[0].road_pic);
});
</script>

<style scoped lang="scss">
.HomePlanPicker-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .list {
    display: flex;
    flex-direction: column;
    & > * {
      margin: 10px 0;
    }
  }
}
</style>
