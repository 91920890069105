<template>
  <div class="HomeLessons-wrapper">
    <el-row type="flex" justify="center">
      <Title label="AI STUDY" title="AI学习" />
    </el-row>
    <el-row style="margin-top: 60px" type="flex" justify="space-between">
      <div class="item" v-for="learn in learnList" :key="learn.learn_id">
        <HomeLearnItem :learn-item="learn" />
      </div>
    </el-row>
    <More :push-name="'learn'" />
    <div class="background">
      <img :src="homeLearnBg" alt="homeLearnBg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import homeLearnBg from '@/assets/svgs/home-study-bg.svg';
import Title from '../utils/Title.vue';
import More from '../utils/More.vue';
import { getLearnList, type LearnInfo } from '@/apis/learn';
import { mapToLessonList } from '@/utils/mapper';
import HomeLearnItem from './HomeLearnItem.vue';
import { CATEGORY_MAP, DIFFICULTY_MAP } from '@/consts/learn';

const learnList = ref<LearnInfo[]>([]);

onMounted(async () => {
  const { data } = await getLearnList({
    page: 1,
    size: 6,
    sortBy: 1,
  });
  learnList.value = mapToLessonList(data, CATEGORY_MAP, DIFFICULTY_MAP);
});
</script>

<style scoped lang="scss">
.HomeLessons-wrapper {
  position: relative;
  margin-top: 60px;
  width: 100%;
  .more {
    width: 86px;
    height: 24px;
    background-color: #ffffff;
    border: 1px solid #dedede;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: dingding;
    color: #111111;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: #eff9fc;
    }
  }
  .placeholder {
    position: relative;
    width: 170px;
    height: 242px;
    margin: 0 15px 30px;
  }
  .background {
    position: absolute;
    top: 50px;
    right: -20px;
    width: 170px;
    height: 119px;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
