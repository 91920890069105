<template>
  <div class="HomeTrainingPlans-wrapper">
    <el-row type="flex" justify="center">
      <div class="title">
        <Title label="AI TRANING PLAN" title="AI学习路线" />
        <div style="margin-top: 17px">
          <span>给初涉AI的你，提供一份切实可行的学习路线</span>
        </div>
      </div>
    </el-row>
    <el-row style="margin-top: 30px" type="flex" justify="center">
      <div style="margin-right: 180px">
        <HomePlanPicker @choose-plan="choosePlan" />
      </div>
      <div class="plan-img">
        <img :src="traningPlan" alt="traningPlan" :class="{ 'fade-transition': isImageChanging }" />
      </div>
    </el-row>
    <div class="background">
      <img :src="homePracticeBg" alt="homePracticeBg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import homePracticeBg from '@/assets/svgs/home-practice-bg.svg';
import HomePlanPicker from './HomePlanPicker.vue';
import Title from '../utils/Title.vue';

const traningPlan = ref<string>('');
const isImageChanging = ref<boolean>(false);

const choosePlan = (pic: string) => {
  isImageChanging.value = true;
  traningPlan.value = pic;
  setTimeout(() => {
    isImageChanging.value = false;
  }, 500);
};

watch(traningPlan, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    isImageChanging.value = true;
    setTimeout(() => {
      isImageChanging.value = false;
    }, 500);
  }
});
</script>

<style scoped lang="scss">
.HomeTrainingPlans-wrapper {
  position: relative;
  width: 100%;
  .title {
    font-size: 14px;
    color: #383a46;
  }
  .plan-img {
    width: 690px;
    height: 470px;
    img {
      width: 100%;
    }
  }
  .background {
    position: absolute;
    width: 242px;
    height: 242px;
    top: -5px;
    left: -75px;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

/* 切换图片的过渡效果 */
.fade-transition {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
